<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div
						:class="
							notification
								? 'col-md-10 col-sm-10'
								: 'col-md-12 col-sm-12'
						"
					>
						<div class="row">
							<div class="col-sm-12">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									Teachers Management / Leave Applications ({{
										tableData.length
									}})
								</h2>
							</div>
							<div class="row">
							<select
								v-model="leaveStatus"
								@change="fetchLeaveApplications"
								class="form-control input-group-alternative py-0 mt-4 mr-3 ml-4 text-dark"
								style="width: 180px; height: 36px"
								id="state"
							>
								<option selected value="pending">
									Pending
								</option>
								<option value="accepted">Accepted</option>
								<option value="rejected">Rejected</option>
							</select>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div v-if="tableData.length > 0" class="table-responsive">
								<base-table
									class="align-items-center"
									:data="pagedTableData"
								>
									<template slot="columns">
										<th class="pr-2 pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
										<th class="pl-3 text-center">
											Start Date
										</th>
										<th class="pl-0 text-center">
											End Date
										</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}</td>
										<td class="col-lg-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-3 mr-3">
													{{row.id !=undefined ?
														row.id.slice(0, 3):row.id}}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-4 name-row">
											<div
												class="row d-flex align-items-center"
											>
												<div class="col-2">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}</span
														>
													</div>
													<div class="col-12">
														<span>{{
															row.qualification
														}}</span>
													</div>
												</div>
											</div>
										</td>
										<td class="col-4 date-row">
											<div
												class="row d-flex text-center justify-content-center align-items-center"
											>
												{{ row.start_date }}
											</div>
										</td>
										<td class="col-2">
											<div
												class="row d-flex text-center justify-content-center text-wrap"
											>
												{{ row.end_date }}
											</div>
										</td>
									</template>
								</base-table>
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pr-2 pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
										<th class="pl-3 text-center">
											Start Date
										</th>
										<th class="pl-0 text-center">
											End Date
										</th>
									</template>
								</base-table>
							<div  class="col-12 d-flex flex-column justify-content-center align-items-center">
									<img src="img/undraw.png" height="250px" width="250px" alt="No data found">
									<h3 class="text-muted mr-4">Oops! No data found</h3>
							</div>
								
						</div>
						<base-pagination
							v-if="tableData.length>0"
								class="mt-5"
								:perPage="itemsPerPage"
								:total="leaveRequestsCount"
								v-model="currentPage"
								align="center"
							/>
						</card>
						<div class="row mt-4 d-flex justify-content-around">
							<div
								v-for="(data, index) in cardData"
								:key="index"
								class="card-sm custom-card rounded shadow bg-white py-3 my-3 my-sm-0 py-sm-3 col-sm mx-2 col-12"
							>
								<div
									:style="
										data.color === 'red'
											? 'background-color:#9EBF6D;'
											: data.color === 'blue'
											? 'background-color:#EB5757;'
											: 'background-color:#F46399'
									"
									:class="
										data.color === 'red'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm text-nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<div
										@click.prevent="redirectTo(data.color)"
										class="btn col-2"
									>
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="5"
						:currentPage="1"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
		<LeaveRequestsModal
			v-if="isOpen"
			:is-open="isOpen"
			:is-modal-open="isModalOpen"
			:student-id="studentId"
			:leave-status="leaveStatus"
		/>
	</div>
</template>
<script>
import LeaveRequestsModal from "./Modals/LeaveRequestsModal.vue";
import Notification from '../../components/Notification.vue';
export default {
	components: { LeaveRequestsModal,Notification },
	data() {
		return {
			leaveStatus:"pending",
			isOpen: false,
			studentId:'',
			itemsPerPage:15,
			currentPage:1			
		};
	},
	computed: {
		notification() {
			return this.$store.state.notifications.notificationStatus;
		},
		tableData() {
			return this.$store.state.LeaveApplicationData.tableData;
		},
		tableHeader() {
			return this.$store.state.LeaveApplicationData.tableHeaders;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "34px",
				width: "34px",
				background: "#2194f6",
			};
		},
		cardData() {
			return this.$store.state.DashboardData.leaveRequestCardData;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.itemsPerPage;
			return this.tableData.slice(start, start + this.itemsPerPage);
		},
		leaveRequestsCount(){
			return this.$store.state.DashboardData.leaveRequestsCount;
		}
	},
	methods: {
		totalTeachers() {
			this.$router.push({ path: "tms" });
		},
		isModalOpen() {
			this.isOpen = !this.isOpen;
			this.fetchLeaveApplications();
		},
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.studentId = id;
		},
		redirectTo(data) {
			if (data === "red" && this.$route.path != "/tms") {
				this.$router.push("/tms");
			} else if (
				data === "blue" &&
				this.$route.path != "/new-applicants"
			) {
				this.$router.push("/new-applicants");
			} else {
				this.$router.push("/interview-completed");
			}
		},
		fetchTeacherDashboardData() {
			this.$store.dispatch("fetchTeacherDashboardData").catch((err) => {
				this.$notify({
					title: "Failed to fetch data",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		fetchLeaveApplications() {
			this.$store.dispatch("fetchLeaveApplications",{status:this.leaveStatus}).then(()=>{
				console.log(this.tableData)
			}).catch((err) => {
				this.$notify({
					title: "Failed to fetch Leave application",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		checkLeaveApplicantDetails() {
			if (this.applicantID !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid applicanr ID " + this.applicantID,
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch(
								"fetchNewApplicantDetails",
								this.applicantID
							)
							.then(() => {
								this.prepareApplicant();
							})
							.catch((err) => {
								this.$notify({
									title: "Failed to fetch applicant details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString(),
								});
							});
					} else {
						this.prepareApplicant();
					}
				}
			}
		},
		closeAddModal() {
			this.isOpen = !this.isOpen;
			this.fetchLeaveApplications();
		},
		fetchNewAppliacantsCount() {
			this.$store
				.dispatch("fetchNewAppliacantCount")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		InterviewCompletedCount() {
			this.$store.dispatch("InterviewCompletedCount").catch((err) => {
				this.$notify({
					title: "Failed to fetch interview completed list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				})
			})

		},
		LeaveApplicationsCount(){
			this.$store.dispatch("LeaveApplicationsCount",{status:'pending'}).then(()=>{
			}).catch((err) => {
				this.$notify({
					title: "Failed to fetch Leave application",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});

		},
	},

	mounted() {
		this.fetchTeacherDashboardData();
		this.fetchLeaveApplications();
		this.fetchNewAppliacantsCount();
		this.InterviewCompletedCount();
		this.LeaveApplicationsCount()
	},
};
</script>
<style scoped>


.custom-card {
	width: 250px !important;
	border-radius: 20px !important;
}
.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
.name-row {
	min-width: 25vw;
	width: 100rem !important;
}
.date-row {
	min-width: 20vw;
	width: 100rem !important;
}
</style>
