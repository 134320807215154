<template>
	<div>
		<modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isModalOpen"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="">
					Teacher Profile :
					<span class="text-orange"> Leave Application</span>
				</h3>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-sm-9">
							<span class="text-sm">{{profileData.id && profileData.id.slice(0,3)}}</span>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.name"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Qualification</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.qualification"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Address</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.address"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Joined Date</label
						>
						<div class="custom-input col-sm-3">
							<span class="text-sm">{{profileData.joined_date && profileData.joined_date.slice(0,10)}}</span>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="profileData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="profileData.whatsapp_number"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="description"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							> Reason for leave</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="description"
								v-model="profileData.description"
							/>
						</div>
					</div>
					<!-- <div class="form-group row">
						<label
							for="link"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div
							:class="
								linkEdit
									? 'custom-input col-sm-4 d-flex '
									: 'custom-edit-input col-sm-4 d-flex '"
						>
							<input
							v-if="profileData.meet_url !=null &&profileData.meet_url.length>0||!linkEdit"
								type="text"
								:readonly="linkEdit"
								style="width: 300px"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="profileData.meet_url"
							/>
							<div class="col-sm-2">
								<base-button
									v-if="linkEdit"
									class=""
									@click="linkEdit = !linkEdit"
									style="
										background-color: #2f80ed;
										border-radius: 20px;
									"
									type="primary"
									size="sm"
									>Update</base-button
								>
								<base-button
									v-else
									class="ml-2 bg-success"
									@click="linkEdit = !linkEdit"
									style="border-radius: 20px"
									type="primary"
									size="sm"
									>Save</base-button
								>
							</div>
						</div>
					</div> -->
					<div class="form-group row">
						<label
							for="payment"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Subjects</label
						>
						<div class="col-sm-8" >
							<button
							v-for="(subject,idx) in profileData.teacher.subjects" :key="idx"
								class="btn text-white btn-sm bg-success text-wrap mb-2"
								@click.prevent=""
							>
								{{subject.name}}
							</button>
						</div>
						
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Leave From</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0 text-danger"
								id="name"
								v-model="profileData.start_date"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Leave To</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0 text-danger"
								id="name"
								v-model="profileData.end_date"
							/>
						</div>
					</div>
					<div
					v-if="leaveStatus=='pending'"
						class="form-group row d-flex align-items-center align-content-center"
					>
						<label
							for="teacher"
							class="col-sm-3 pt-2 col-form-label py-0 text-sm font-weight-bold"
							>Assign New Teacher</label
						>

						<div class="custom-input col-sm-8 d-flex">
							<multiselect
								class="custom-multi-select"
								:multiple="false"
								v-model="leaveData.alternative_teacher"
								:options="teachersList"
								label="name"
								track-by="name"
                                :hide-selected="true"
                                :close-on-select="true"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>
						</div>
					</div>
				</form>
			</div>
			<template  v-slot:footer>
				<base-button
				v-if="leaveStatus=='pending'"
					type="primary"
					@click.prevent="rejectLeave()"
					style="
						height: 46px;
						border-radius: 20px;
						background-color: #bb6bd9;
					"
					class="mb-4 col-2"
					>Reject</base-button
				>
				<base-button
				v-if="leaveStatus=='pending'"
					@click.prevent="acceptLeave()"
					type="primary"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-4 col-4"
					>Approve leave and assign</base-button
				>
				<base-button
				v-if="leaveStatus=='accepted'"
					@click.prevent="assign"
					type="primary"
					style="
						width: 140px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-4 col-3"
					>Reassign</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
	components: {
		Multiselect,
	},
	props: ["isOpen", "isModalOpen", "studentId","leaveStatus"],
	data() {
		return {
			profileEdit: true,
			linkEdit: true,
			teacherValue: ["Mrs Radha"],
			teacherList: ["Mrs Radha", "Mr Davis", "Mrs Jiya", "Mr Brad"],
			leaveData: {
				id:"",
				alternative_teacher:"",
				status:""
			}
		};
	},
	computed: {
		tableData() {
			return this.$store.state.LeaveApplicationData.tableData;
		},
		profileData() {
			return this.tableData.find((data) => {
				return data.id === this.studentId;
			});
		},
		teachersList() {
			return this.$store.state.TmsData.tableData;
		},
		teacherCount() {
			return this.$store.state.TmsData.teacherCount;
		},
		
	},
	methods: {
		rejectLeave() {
			this.leaveData.id = this.profileData.id
			this.leaveData.status="rejected"
			this.leaveData.alternative_teacher= ""
			
			this.$store
				.dispatch("setLeaveApplicantsDetails", {leaveData:this.leaveData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen()
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to reject leave request",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		acceptLeave() {
			// var error = undefined;

			if (this.leaveData.alternative_teacher.id == undefined) {
				// error = "Alternative teacher is required";
				this.leaveData.alternative_teacher = this.leaveData.alternative_teacher.id
			}
			else this.leaveData.alternative_teacher = null
			// if (error) {
			// 	this.$notify({
			// 		title: "Error",
			// 		icon: "fa fa-exclamation-triangle",
			// 		type: "danger",
			// 		message: error
			// 	});
			// 	return;
			// }
			
			this.leaveData.id = this.profileData.id
			this.leaveData.status = "accepted"

			
			this.$store
				.dispatch("setLeaveApplicantsDetails", {leaveData:this.leaveData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen();
					this.$store.dispatch('fetchNotificationsList');
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to accept leave request",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		assign(){
			this.$store
				.dispatch("setTeacherReassign", this.profileData)
				.then(() => {
					this.isModalOpen()
					})
				.catch((err) => {
					this.$notify({
						title: "Failed to re-assign teacher",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchLeaveApplicantsDetails() {
			this.$store
				.dispatch("fetchLeaveApplicantsDetails", this.studentId)
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch Leave applicants details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchTeachersList() {
			this.$store
				.dispatch("fetchTeachersList",{searchTerm:'',itemsPerPage:this.teacherCount,startIndex:''})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Teachers",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		// fetchCourseList() {
		// 	this.$store.dispatch("fetchCourseList",{itemsPerPage:this.subjectsCount,startIndex:''}).catch((err) => {
		// 		this.$notify({
		// 			title: "Failed to fetch course list",
		// 			icon: "fa fa-exclamation-triangle",
		// 			type: "danger",
		// 			message: err.toString(),
		// 		});
		// 	});
		// },
	},
	mounted() {
		this.fetchTeachersList()
		this.fetchLeaveApplicantsDetails();
		// this.fetchCourseList();
	
	},
};
</script>
<style scoped>
.custom-input input {
	width: 250px !important;
	height: 21px;
	outline: none;
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input input:focus {
	outline: none;
}
.custom-multi-select >>> .multiselect__tags {
	border: 0px !important;
	margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
